// api模块
import config from "@/common/config"
import tool from "@/common/tool"
const urls = {
    openid: "/api/openid",
    jsSign: "/api/jssign",
    jssdk: "/api/jssdk",
    user: {
        code: "/admin/sms",
        login: "/admin/login"
    },
    uploads: {
        uploadFile: "/api/upload_file",
        uploadBase64: "/api/uploadbase64",
        video:"/api/upload_file_voice"
    },
    admin: {
        doctorList: "/admin/doctor_list",
        meetingCount: "/admin/detail",
        doctorInfo: "/admin/doctor_info",
        doctorMeetingList: "/admin/doctor_meet_list",
        meet: "/admin/meet",
        meetDetail: "/doctor/meet_detail",
        meetSign: "/admin/meet_sign",
        meetError: "/admin/meet_error",
        getCourseInfo: "/api/class_info",
        auditStatus: "/admin/audit_status",
        project: "/api/project_detail",
        admin_share: "/api/admin_send_ppt",
        preview: '/api/doctor_page'
    },
    doctor: {
        meetList: "/doctor/meet_list",
        historyMeetList: "/doctor/history_meet_list",
        doctorInfo: "/doctor/info",
        register: "/doctor/register",
        hospitals: "/api/hospital",
        departments: "/api/department",
        meetSign: "/api/sign_meet",
        agreementField: "/api/agreement_field",
        hasPage: "/api/has_page",
        image: "/admin/doctor_profile"
    },
    meet: {
        sign: "/api/sign_live",
        fastReply: "/api/custom_question",
        reset: "/api/meet_reset",
        customQuestion: "/api/courseware_content",
        pageContent: '/api/custom_content',
        upload: "/api/upload_many_files",
        uploadWeixin: '/api/upload_wx_image'
    },
    api: {
        project: "/api/project_list",
        courseware: "/api/class_list",
        meetDetail: "/api/meet_detail",
        sendEmail: "/api/class_send",
        pptxDownload: "/api/send_ppt" //幻灯提词下载
    },
    literature: {
        voteList: "https://kclass.imedunion.com/api/vote_list",//投票列表
        myVote: "https://kclass.imedunion.com/api/vote",//我的投票  
        vote: "https://kclass.imedunion.com/api/vote", //投票上传
        periodList: "https://kclass.imedunion.com/api/period_list",//期数列表
        voteDetail: "https://kclass.imedunion.com/api/vote_detail",//投票详情
    },
    patientShare: {
        voteList: "https://mtzls.imedunion.com/api/vote_list",//投票列表
        myVote: "https://mtzls.imedunion.com/api/vote",//我的投票  
        vote: "https://mtzls.imedunion.com/api/vote", //投票上传
        periodList: "https://mtzls.imedunion.com/api/period_list",//期数列表
        voteDetail: "https://mtzls.imedunion.com/api/vote_detail",//投票详情
    }

}

let host = tool.isLocal() ? config.localhost : config.host;
for (let key in urls) {
    if (typeof urls[key] == "object") {
        for (let i in urls[key]) {
            urls[key][i] = urls[key][i].includes("http") ? urls[key][i] : `${host}${urls[key][i]}`;
        }
        continue
    }
    urls[key] = urls[key].includes("http") ? urls[key] : `${host}${urls[key]}`;
}
export default urls;